import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';


export const environment = {  
	production: false,  
	SOCKET_ENDPOINT: 'http://localhost:3000'
};
export class SocketioService {

  socket;

  constructor() {   }

  
}


