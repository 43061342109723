import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { from, Observable, of, } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserModel } from '../Models/interface-model';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
  userphoto_default = 'assets/img/user_default.png'
  usersRef = this.db.list('users');
  authState: Observable<firebase.User>;
  user: firebase.User;
  constructor(
     private ngFireAuth: AngularFireAuth,public db: AngularFireDatabase) {
      this.authState = ngFireAuth.authState;

      this.authState.subscribe(user => {
        this.user = user;
      })
  }
  loginUser(email, password): Observable<boolean> {
    console.log(email)
    return from(this.ngFireAuth.auth.signInWithEmailAndPassword(email, password)).pipe(
      map(v => true),
      catchError(err => {
        console.log(err)
        return of(false)
      })
    )

  }
  resetPassword(email):Observable<boolean>{
    console.log(email)
    return from(this.ngFireAuth.auth.sendPasswordResetEmail(email)).pipe(
       
      map(v => {console.log(v) 
        return true}),
      catchError(err => {
        console.log(err)
        return of(false)
      })
    )
   
  }
  signUp(email,password,name,surname):Observable<boolean>{
    return from(this.ngFireAuth.auth.createUserWithEmailAndPassword(email,password)).pipe(
        tap(newUser => {
            this.db.list('/users').update(newUser.user.uid, {
              email: email,
              name: name+" "+surname,
              //dob:วันเกิด utc
    
              photoUrl: this.userphoto_default,
              nickname: "",
              birthDate: "",
              gender:"",
              weight:"0",
              height:"0",
            })
          }),
      map(v => true),
      catchError(err => {
        console.log(err)
        return of(false)
      })
    )
    
  }
  logoutUser(): Observable<void> {
    return from(this.ngFireAuth.auth.signOut())
  }

  public async changePassword(email,oldPassword, newPassword): Promise<Boolean> {
    const credentials = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
    var result = await firebase.auth().currentUser.reauthenticateWithCredential(credentials).then(
      await function (): boolean {
        var user = auth().currentUser;
        user.updatePassword(newPassword).then(() => { console.log("success") }).catch((error) => { alert(error) });
        return true;
      }
    ).catch(function (error) {
      console.log("error");
      return false;
    });
    console.log(result)
    return result;
  }
  getCurrentUserId() {
    return this.ngFireAuth.auth.currentUser.uid;
  }
  getCurrentUserEmail() {
    return this.ngFireAuth.auth.currentUser.email;
  }
  // getAllUsers() {
  //   return this.usersRef.snapshotChanges()
  //     // .map(actions => {
  //     //   return actions.map(action => ({ id: action.key, ...action.payload.val() }))
  //     // })
  //     // .map(users => {
  //     //   return users.filter(user => user.id !== this.afAuth.auth.currentUser.uid);
  //     // })
  // }

  updateCurrentUserPhoto(url: string) {
    return this.db.object('users/' + this.ngFireAuth.auth.currentUser.uid)
      .update({ photoUrl: url });
  }
  updateCurrentUserPhotoToDefalut() {
    return this.db.object('users/' + this.ngFireAuth.auth.currentUser.uid)
      .update({ photoUrl: this.userphoto_default });
  }

  getCurrentUser(): Observable<UserModel> {

    let myId = this.ngFireAuth.auth.currentUser.uid;
    return this.db.object('users/' + myId).snapshotChanges().pipe(map(action => {
      console.log("action")
      console.log(action)
      let o: any = action.payload.val();
      let u: UserModel = {};

      u.id = action.key;
      u.name = o.name;
      u.photoUrl = o.photoUrl;
      u.email = o.email;

      u.nickname = o.nickname;
      u.birthdate = o.birthdate;
      u.gender = o.gender;

      u.weight = o.weight;
      u.height = o.height;
      return u;
    }));
  }

  getUserById(userId): Observable<UserModel> {
    return this.db.object('users/' + userId).snapshotChanges().pipe(map(action => {

      let o: any = action.payload.val();
      let u: UserModel = {};

      u.id = action.key;
      u.email = o.email;
      u.name = o.name;
      u.photoUrl = o.photoUrl;
      u.nickname = o.nickname;
      u.birthdate = o.birthDate;
      u.gender = o.gender;
      u.weight = o.weight;
      u.height = o.height;

      return u;
    }));
  }

  CheckBluetoothIDValid(bluetoothId): Promise<any> {
    var ref = firebase.database().ref("bluetooth/" + bluetoothId);
    return ref.once("value")
  }

  getNameById(userId): Observable<UserModel> {
    return this.db.object('users/' + userId).snapshotChanges().pipe(map(action => {
      let o: any = action.payload.val();
      let u: UserModel = {};
      u.name = o.name;
      return u;
    }));
  }


  getphotoUrlById(userId): Observable<UserModel> {
    return this.db.object('users/' + userId).snapshotChanges().pipe(map(action => {
      let o: any = action.payload.val();
      let u: UserModel = {};
      u.photoUrl = o.photoUrl;
      return u;
    }));
  }
}
