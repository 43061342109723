import { Component } from '@angular/core';
import { StatusBar } from '@capacitor/status-bar';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from 'src/providers/services/Authservice';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    UserServiceProvider: AuthService,
    navCtrl: NavController,
    platform: Platform, private androidPermissions: AndroidPermissions) {
  }
}
