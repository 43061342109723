import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'realtimetag',
    loadChildren: () => import('./realtimetag/realtimetag.module').then( m => m.RealtimetagPageModule)
  },
  {
    path: '',
    redirectTo: 'tag',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tag',
    loadChildren: () => import('./tag/tag.module').then( m => m.TagPageModule)
  },
  {
    path: 'realtimerace',
    loadChildren: () => import('./realtimerace/realtimerace.module').then( m => m.RealtimeracePageModule)
  },  {
    path: 'hometag',
    loadChildren: () => import('./hometag/hometag.module').then( m => m.HometagPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
